exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-acadex-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kit/Code/projects/portfolio/src/pages/projects/raws/acadex.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-acadex-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-gastack-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kit/Code/projects/portfolio/src/pages/projects/raws/gastack.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-gastack-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-goma-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kit/Code/projects/portfolio/src/pages/projects/raws/goma.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-goma-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-simplygopal-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kit/Code/projects/portfolio/src/pages/projects/raws/simplygopal.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-simplygopal-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-triviago-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kit/Code/projects/portfolio/src/pages/projects/raws/triviago.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-triviago-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-wardle-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/kit/Code/projects/portfolio/src/pages/projects/raws/wardle.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-users-kit-code-projects-portfolio-src-pages-projects-raws-wardle-mdx" */),
  "component---src-pages-projects-raws-acadex-mdx": () => import("./../../../src/pages/projects/raws/acadex.mdx" /* webpackChunkName: "component---src-pages-projects-raws-acadex-mdx" */),
  "component---src-pages-projects-raws-gastack-mdx": () => import("./../../../src/pages/projects/raws/gastack.mdx" /* webpackChunkName: "component---src-pages-projects-raws-gastack-mdx" */),
  "component---src-pages-projects-raws-goma-mdx": () => import("./../../../src/pages/projects/raws/goma.mdx" /* webpackChunkName: "component---src-pages-projects-raws-goma-mdx" */),
  "component---src-pages-projects-raws-simplygopal-mdx": () => import("./../../../src/pages/projects/raws/simplygopal.mdx" /* webpackChunkName: "component---src-pages-projects-raws-simplygopal-mdx" */),
  "component---src-pages-projects-raws-triviago-mdx": () => import("./../../../src/pages/projects/raws/triviago.mdx" /* webpackChunkName: "component---src-pages-projects-raws-triviago-mdx" */),
  "component---src-pages-projects-raws-wardle-mdx": () => import("./../../../src/pages/projects/raws/wardle.mdx" /* webpackChunkName: "component---src-pages-projects-raws-wardle-mdx" */)
}

